<template>
    <div>
        <div class="p-4">
            <b-card>
                <b-card-title style="margin-bottom: 45px">
                    Products
                    <div class="float-right">
                        <b-btn variant="success" size="md" @click="addItem"> +
                        </b-btn>
                    </div>
                    </b-card-title>
                <b-modal id="addProductModal" title="Add Product"
                         :no-close-on-backdrop="true" modal-class="modal-right" size="lg"
                         hide-footer
                         >
                    <AddProduct @success="ItemAdded"></AddProduct>
                </b-modal>
                <vue-table :pageSizes="[10, 20, 50, 100]" ref="table" :url="apiBase" :fields="fields" :perPage="10">
                    <template slot="actions" slot-scope="props">
                        <div class="btn-group">
                            <b-btn variant="info" size="sm" @click="editItem(props.rowData)">
                                <b-icon-pencil-square></b-icon-pencil-square>
                                Edit
                            </b-btn>
                            <b-btn variant="danger" size="sm" @click="deleteItem(props.rowData.id)">
                                <b-icon-trash-fill></b-icon-trash-fill>
                                Delete
                            </b-btn>
                        </div>
                    </template>
                </vue-table>
                <b-modal id="editProductModal" :no-close-on-backdrop="true" modal-class="modal-right" size="lg"
                         hide-footer
                         title="Edit Product">
                    <EditProduct :model="EditingModel" @success="itemEdited"></EditProduct>
                </b-modal>
            </b-card>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import AddProduct from './AddProduct';
import EditProduct from './EditProduct';

export default {
    name: 'ListProduct',
    components: { AddProduct, EditProduct },
    data () {
        return {
            apiBase: urls.admin.product.list,
            EditingModel: {
                id: '',
                product_name: '',
                mrp: '',
                selling_price: '',
                disabled: '',
                product_image: '',
                description: '',
                product_category: '',
                quantity: '',
                display_name: ''
            },
            EditingProduct: null,
            fields: [
                {
                    name: 'id',
                    sortField: 'id',
                    title: 'ID',
                    titleClass: ''
                },
                {
                    name: 'product_name',
                    sortField: 'product_name',
                    title: 'Product Name',
                    titleClass: ''
                },
                {
                    name: 'mrp',
                    sortField: 'mrp',
                    title: 'MRP',
                    titleClass: ''
                },
                {
                    name: 'selling_price',
                    sortField: 'selling_price',
                    title: 'Selling Price',
                    titleClass: ''
                },
                {
                    name: 'disabled_text',
                    title: 'Active / Inactive',
                    titleClass: ''
                },
                {
                    name: 'product_category.label',
                    title: 'Product Category',
                    titleClass: ''
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        addItem () {
            this.$bvModal.show('addProductModal');
        },
        ItemAdded () {
            this.$bvModal.hide('addProductModal');
            this.$refs.table.refreshTable();
        },
        editItem (item) {
            // console.log('item =>', item);
            this.EditingProduct = item;
            this.EditingModel.id = item.id;
            this.EditingModel.product_name = item.product_name;
            this.EditingModel.mrp = item.mrp;
            this.EditingModel.selling_price = item.selling_price;
            this.EditingModel.disabled = item.disabled;
            this.EditingModel.product_image = item.product_image;
            this.EditingModel.description = item.description;
            this.EditingModel.product_category = item.product_category.value;
            this.EditingModel.quantity = item.quantity;
            this.EditingModel.display_name = item.display_name;
            // console.log('EditingModel =>', this.EditingModel);
            this.$bvModal.show('editProductModal');
        },
        itemEdited () {
            this.$bvModal.hide('editProductModal');
            this.$refs.table.refreshTable();
        },
        deleteItem (item) {
            const conform = confirm('Are you sure?');
            if (!conform) {
                return;
            }
            const that = this;
            axios.form(urls.admin.product.delete, { id: item }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.deleteSuccess();
                } else {
                    that.deleteFailure(json);
                }
            }).catch(function () {
                that.$notify('Something went wrong..!', 'Error',
                    {
                        type: 'warning',
                        duration: 3000,
                        permanent: false
                    }
                );
            });
        },
        deleteSuccess () {
            this.$notify('Successfully deleted Product..!', 'success',
                {
                    type: 'error',
                    duration: 3000,
                    permanent: false
                });
            this.$refs.table.refreshTable();
        },
        deleteFailure (json) {
            // console.log(json);
            this.$notify(json.errors, 'Error',
                {
                    type: 'warning',
                    duration: 3000,
                    permanent: false
                }
            );
        }
    }
};
</script>

<style scoped>

</style>
